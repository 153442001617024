import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGearComplex } from '@fortawesome/pro-light-svg-icons/faGearComplex'

import Modal, { IModal } from 'shared/components/Modal'

export interface IFixPermissionsButton {
  open: () => void
  close: () => void
}

const FixPermission = () => {
  const modalRef = React.useRef<IModal>(null)
  return (
    <>
      <div className='fixed top-0 left-0 w-screen h-screen bg-blackAlpha-600 flex flex-col justify-start items-center'>
        <div className='portrait:w-full landscape:max-w-md flex flex-grow-0 flex-row relative gap-2 bg-red-500 py-5 px-4 landscape:rounded-b items-center pointer-events-none'>
          <p className='text-4xl'>😩</p>
          <p className='flex text-start text-md text-white whitespace-normal max-w-xs'>
            Fix permissions for your camera and microphone
          </p>
        </div>
        <div className='flex justify-start items-center h-full'>
          <button
            className='h-fit-content flex items-center gap-2 py-4 px-12 bg-whiteAlpha-900 rounded-sm border border-gray-300'
            onClick={() => modalRef.current?.open()}
          >
            <FontAwesomeIcon icon={faGearComplex} />
            <p className='text-black font-semibold text-md'>Fix Permissions</p>
          </button>
        </div>
      </div>
      <Modal
        overlay
        ref={modalRef}
        customFooter={
          <div className='text-[18px] w-full flex flex-col justify-center p-4'>
            <p className='font-bold text-gray-800 text-sm'>
              Still not working?
            </p>
            <button className='px-12 py-1 border mt-4 rounded-lg font-[#4A5568]'>
              Ask for help
            </button>
          </div>
        }
      >
        <div className='pt-8 relative portrait:h-full landscape:h-[70vh] landscape:w-[30vw] overflow-auto'>
          <div className='text-gray-800 text-[18px]'>
            <h6 className='text-center font-bold text-md'>
              In order to record, we need permission from you to use your
              device’s camera and microphone.
            </h6>
            <p className='text-center pt-8 text-sm'>
              A pop-up window should appear asking for permission, but if you’re
              experiencing issues with this, try these steps:
            </p>
          </div>
          <ol className='text-gray-600 list-decimal text-sm pt-12 list-inside'>
            <li className='pt-4'>
              Has your browser blocked pop-ups? If so, you may not see the
              pop-up window to grant camera and microphone permissions.
              <ul className='list-disc pl-4'>
                <li>
                  You can either unblock pop-ups to see it or click the lock
                  icon to the left of the website URL and set both camera and
                  microphone permissions to Allow.
                </li>
              </ul>
            </li>
            <li className='pt-4'>
              Is your browser up to date? If you&apos;re not using the latest
              version, updating your browser can sometimes quickly resolve this
              (and many other!) issues.
            </li>
            <li className='pt-4'>
              Are you using any browser plug-ins or extensions? Do any of them
              have access to your camera and microphone permissions? If so, they
              may be conflicting with VideoAsk, so it&apos;s worth turning them
              off to see if that resolves the issue.
            </li>
            <li className='pt-4'>
              Have you tried using a different browser or device? Does this
              work?
            </li>
          </ol>
        </div>
      </Modal>
    </>
  )
}

export default FixPermission
