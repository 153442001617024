import React from 'react'

const BASE_STYLES = 'p-4 rounded-full flex-row flex'
const COLOR_SCHEMES = {
  default: `${BASE_STYLES} bg-whiteAlpha-300 hover:bg-whiteAlpha-200`,
  defaultActive: `${BASE_STYLES} bg-gray-300 hover:bg-gray-200`,
  red: `${BASE_STYLES} bg-red-400 hover:bg-red-300`
}

const CircleButton = ({
  children,
  colorScheme,
  onClick,
  disabled = false
}: {
  children: React.ReactNode
  active?: boolean
  colorScheme?: string
  onClick: () => void
  disabled?: boolean
}) => {
  return (
    <button
      className={COLOR_SCHEMES[colorScheme ?? 'default']}
      onClick={onClick}
      disabled={disabled}
    >
      <div className='w-4 h-4 flex justify-center items-center'>
        {children}
      </div>
    </button>
  )
}

export default CircleButton
