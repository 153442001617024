import { FC, RefObject, useEffect, useState, useRef } from 'react'

type Props = {
  streamRef: RefObject<MediaStream | null>
  isRecording: boolean
  onSilenceDetected: () => void
  delay?: number
}

const DELAY = 2000

const SilenceDetector: FC<Props> = ({
  streamRef,
  isRecording,
  onSilenceDetected,
  delay
}) => {
  const [soundDetected, setSoundDetected] = useState(true)
  const lastSoundTimeRef = useRef<number>(Date.now())
  const [initialSoundDetected, setInitialSoundDetected] = useState(false)
  const [lastSoundTime, setLastSoundTime] = useState(Date.now())
  const analyzerRef = useRef<AnalyserNode | null>(null)
  const timer = useRef<number>(0)
  const domainDataRef = useRef<Uint8Array | null>(null)

  useEffect(() => {
    if (isRecording) {
      setLastSoundTime(Date.now())
      setInitialSoundDetected(false)
      lastSoundTimeRef.current = Date.now()
    } else {
    }
  }, [isRecording])

  useEffect(() => {
    if (!soundDetected && isRecording && initialSoundDetected) {
      setInitialSoundDetected(false)
      setSoundDetected(false)
      onSilenceDetected()
    }
  }, [!soundDetected, isRecording, initialSoundDetected])

  const detectSound = () => {
    if (analyzerRef.current && domainDataRef.current) {
      const bufferLength = analyzerRef.current.frequencyBinCount
      // console.log('detectSound, bufferLength', bufferLength)
      analyzerRef.current.getByteFrequencyData(domainDataRef.current)
      const d = delay || DELAY
      let detected = lastSoundTimeRef.current + d >= Date.now()

      // console.log('detected', detected)

      for (let i = 0; i < bufferLength; i++) {
        // const value = domainData[i];

        if (domainDataRef.current[i] > 0) {
          detected = true
          lastSoundTimeRef.current = Date.now()
          setLastSoundTime(Date.now())
          setInitialSoundDetected(true)
        }
      }

      setSoundDetected(detected)
    }
  }

  useEffect(() => {
    // SILENSE Analizer
    if (isRecording && streamRef.current) {
      console.log('start silence analysing')
      const MIN_DECIBELS = -60
      const audioContext = new AudioContext()
      const audioStreamSource = audioContext.createMediaStreamSource(
        streamRef.current
      )
      const analyser = audioContext.createAnalyser()
      analyser.minDecibels = MIN_DECIBELS
      audioStreamSource.connect(analyser)
      analyzerRef.current = analyser

      const bufferLength = analyser.frequencyBinCount
      domainDataRef.current = new Uint8Array(bufferLength)

      timer.current = window.setInterval(detectSound, 200)
    } else {
      clearInterval(timer.current)
    }

    return () => clearInterval(timer.current)
  }, [isRecording])

  return null

  // return (
  //   <div className='bg-whiteAlpha-400 z-10'>
  //     <p>
  //       {soundDetected ? 'sound' : 'silence'} - {lastSoundTime}
  //     </p>
  //     <p>
  //       {initialSoundDetected ? 'waiting for silence' : 'waiting for voice'} -{' '}
  //       {lastSoundTime}
  //     </p>
  //     <p>isRecording: {isRecording.toString()}</p>
  //   </div>
  // )
}

export default SilenceDetector
