import { ReactComponent as FFLogo } from 'shared/assets/facesign_logo.svg'

const EndConversation = () => (
  <div className='flex flex-col w-full h-full items-center justify-start'>
    <div className='flex py-28'>
      <FFLogo />
    </div>
    <div className='flex flex-col gap-8 py-28 text-center'>
      <p className='text-2xl font-gray-800'>Your FaceSign call is over.</p>
      <p className='text-lg font-blackAlpha-800'>Thank you!</p>
    </div>
  </div>
)

export default EndConversation
