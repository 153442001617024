import { FC, useRef } from 'react'

import { ReactComponent as IconLogo } from 'shared/assets/facesign_logo.svg'
import PageContainer from 'shared/components/PageContainer'

type Props = {
  onRetry: () => void
}

const PSafariMobile: FC<Props> = ({ onRetry }) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  return (
    <PageContainer>
      <div className='w-full h-full relative bg-white flex flex-col items-center justify-start'>
        <div className='flex flex-row w-full px-5 justify-center pt-9'>
          <IconLogo />
        </div>
        <span className='w-[345px] font-bold text-[20px] text-center pt-5'>
          Enable Camera and Microphone
        </span>
        <span className='text-center text-[12px] text-gray-600 pt-[10px]'>
          Follow these steps to set your permissions settings to <b>allow.</b>
        </span>
        <div className='w-[190px] h-[300px] mt-6 relative overflow-hidden'>
          <video
            ref={videoRef}
            className='w-[190px] absolute left-0 bottom-0'
            src='https://firebasestorage.googleapis.com/v0/b/tenantflow-ace23.appspot.com/o/permissions%2Fsafari_mobile.mp4?alt=media&token=54c4fa5f-f1be-40d7-8108-76e5862c41db'
            poster='https://firebasestorage.googleapis.com/v0/b/tenantflow-ace23.appspot.com/o/permissions%2Fsafari_video_thumbnail.png?alt=media&token=2e1cd97e-5840-478d-b770-bcaedd084226'
            autoPlay
            playsInline
            // controls
            muted
            loop
            onCanPlay={() => {
              if (videoRef.current) {
                videoRef.current.playbackRate = 2
              }
            }}
          />
        </div>
        <div
          className={'w-full px-5 flex flex-col pt-5 text-gray-600 text-[14px]'}
        >
          <div className='w-full flex flex-row justify-between items-center'>
            <span>1. Tap the “aA” icon</span>
            <img
              className='w-[29px]'
              src='https://firebasestorage.googleapis.com/v0/b/tenantflow-ace23.appspot.com/o/permissions%2Fsafari_mobile_step1.png?alt=media&token=425f7f09-8ba3-4f5d-bc42-9e2d66ef2b94'
            />
          </div>
          <div className='w-full flex flex-row justify-between items-center pt-5'>
            <span>2. Tap “Website Settings”</span>
            <img
              className='w-[145px]'
              src='https://firebasestorage.googleapis.com/v0/b/tenantflow-ace23.appspot.com/o/permissions%2Fsafari_mobile_step2.png?alt=media&token=5991b69f-4deb-4e23-b210-c97292dfa887'
            />
          </div>
          <div className='w-full flex flex-row justify-between items-center pt-5'>
            <div className='flex flex-col'>
              <span>3. Change both “Camera” and</span>
              <span className='pl-3'>“Microphone” to Allow</span>
            </div>
            <img
              className='w-[106px]'
              src='https://firebasestorage.googleapis.com/v0/b/tenantflow-ace23.appspot.com/o/permissions%2Fsafari_mobile_step3.png?alt=media&token=2aacf5df-56b7-4679-8985-90405d47bab1'
            />
          </div>
          <div
            className='w-full px-5 h-[60px] bg-teal-500 mt-6 flex flex-row items-center justify-center text-whiteAlpha-900 hover:cursor-pointer'
            onClick={onRetry}
          >
            <span className='pl-2 text-[20px] font-semibold '>Retry</span>
          </div>
        </div>
      </div>
    </PageContainer>
  )
}

export default PSafariMobile
