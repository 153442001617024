import { FC } from 'react'

import { ControlT } from 'shared/types/facesign'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCameraSlash } from '@fortawesome/pro-light-svg-icons/faCameraSlash'
import { faMicrophoneSlash } from '@fortawesome/pro-light-svg-icons/faMicrophoneSlash'

type Props = {
  controls: ControlT
  handleMicOrCameraClick: (v: keyof ControlT) => void
}

const RecorderControls: FC<Props> = ({ controls, handleMicOrCameraClick }) => {
  const renderContent = () => {
    if (!controls.camera && !controls.mic) {
      return (
        <div className='flex flex-col portrait:gap-8 landscape:gap-12'>
          <div onClick={() => handleMicOrCameraClick('camera')}>
            <FontAwesomeIcon icon={faCameraSlash} />
          </div>
          <div onClick={() => handleMicOrCameraClick('mic')}>
            <FontAwesomeIcon icon={faMicrophoneSlash} />
          </div>
        </div>
      )
    } else if (!controls.mic) {
      return (
        <div onClick={() => handleMicOrCameraClick('mic')}>
          <FontAwesomeIcon icon={faMicrophoneSlash} />
        </div>
      )
    } else if (!controls.camera) {
      return (
        <div onClick={() => handleMicOrCameraClick('camera')}>
          <FontAwesomeIcon icon={faCameraSlash} />
        </div>
      )
    }
  }

  if (controls.camera && controls.mic) {
    return null
  } else {
    return (
      <div className='w-full h-full absolute top-0 left-0 text-white z-10 flex items-center justify-center landscape:text-5xl portrait:text-4xl'>
        {renderContent()}
      </div>
    )
  }
}

export default RecorderControls
